/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-16",
    versionChannel: "nightly",
    buildDate: "2023-07-16T00:22:13.709Z",
    commitHash: "cd2d01bf6f2565e1aa442da671ca7e27ed186629",
};
